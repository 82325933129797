<template>
  <v-container fluid>
    <div>
      <!-- le workflow applicatif -->
      <div class="d-flex justify-center" flat tile>
        <Workflow
          width="600"
          height="180"
          :steps="workflowSteps"
          :currentStep="workflowIndex"
          :labelWidth="200"
          :lineWidth="140"
        ></Workflow>
      </div>

      <v-row justify="center">
        <v-col xl="6" lg="8" md="8" sm="10" xs="12">
          <!-- le titre et le bouton retour -->
          <div class="d-flex justify-center">
            <TitleAndReturnComponent
              title="Application Avec > Super établissements visibles"
            />
          </div>

          <!-- la barre de progression -->
          <v-row justify="center" no-gutters>
            <v-col xl="6" lg="8" md="8" sm="10" xs="12">
              <!-- la progess bar à afficher lors du chargement des données -->
              <v-progress-linear
                indeterminate
                :active="loading"
              ></v-progress-linear>
            </v-col>
          </v-row>

          <!-- l'affichage de la table -->
          <v-card flat outlined class="my-4">
            <v-card-title class="font-weight-regular">
              <v-row no-gutters justify="space-between">
                <div>Configuration</div>
              </v-row>
            </v-card-title>

            <div class="d-flex align-center">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Rechercher"
                single-line
                class="mx-5"
                clearable
              ></v-text-field>
            </div>

            <v-data-table
              locale="fr-FR"
              :headers="headers"
              :items="superEstablishments"
              :loading="false"
              :search="search"
              no-data-text
              no-results-text="aucuns éléments"
              hide-default-footer
              disable-pagination
            >
              <template v-slot:[getItemVisible()]="{ item }">
                <v-simple-checkbox
                  on-icon="mdi-checkbox-outline"
                  off-icon="mdi-checkbox-blank-outline"
                  :ripple="false"
                  v-model="item.visible"
                  color="primary"
                />
              </template>
            </v-data-table>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                outlined
                class="ma-2 px-4 btn"
                color="primary"
                :disabled="!hasChanged"
                @click="save"
              >
                <div class="capitalize">
                  enregistrer
                </div>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </div>

    <v-layout column class="fab-container ">
      <v-btn
        class="mx-2 my-2"
        fab
        elevation="0"
        v-scroll="onScroll"
        :disabled="!canScrollToTop"
        color="primary"
        @click="toTop"
      >
        <v-icon :disabled="!canScrollToTop">mdi-chevron-up</v-icon>
      </v-btn>

      <v-btn
        class="mx-2 my-1"
        fab
        elevation="0"
        v-scroll="onScroll"
        :disabled="!canScrollToBottom"
        @click="toBottom"
        color="primary"
      >
        <v-icon :disabled="!canScrollToBottom">mdi-chevron-down</v-icon>
      </v-btn>
    </v-layout>

    <!-- Fenetre modale pour éviter que l'utilisateur quitte sans sauvegarder -->
    <AlertNotSavedModifsComponent
      :show="showAlertQuit"
      @quit="onQuitAlert"
      @notquit="onNotQuitAlert"
    />

    <!-- afficher des messages -->
    <v-snackbar
      v-model="snackbarVisible"
      :color="snackbarColor"
      :timeout="snackbarTimeout"
      :left="snackbarLeft"
      :right="snackbarRight"
      :top="snackbarTop"
      :bottom="snackbarBottom"
      >{{ snackbarMessage }}</v-snackbar
    >
  </v-container>
</template>

<script>
import Workflow from "@/components/Workflow.vue";
import WorkflowMixin from "@/components/mixins/WorkflowMixin.js";

import SnackBarMixin from "@/components/mixins/SnackBarMixin.js";

import AlertNotSavedModifsMixin from "@/components/mixins/AlertNotSavedModifsMixin.js";
import AlertNotSavedModifsComponent from "@/components/ui/AlertNotSavedModifsComponent.vue";

import TitleAndReturnComponent from "@/components/ui/TitleAndReturnComponent.vue";

import { SuperEstablishmentService } from "@/service/etablishment/super_establishment_service.js";
import { ConfAppAvecSuperEstablishmentService } from "@/service/conf/app_avec_super_establishment_service.js";

import * as exceptions from "@/service/exception_to_message.js";
import * as log from "@/tools/logger.js";

export default {
  name: "ConfAvecEstablishment",
  components: {
    Workflow,
    TitleAndReturnComponent,
    AlertNotSavedModifsComponent,
  },
  mixins: [WorkflowMixin, SnackBarMixin, AlertNotSavedModifsMixin],

  data() {
    return {
      /** en cours de chargement */
      loading: false,

      /** Les services */
      serviceSuperEstablishment: null,
      serviceConf: null,

      /** les headers de la table */
      headers: [
        {
          text: "Nom digital",
          value: "digitalName",
          sortable: true,
          align: "start",
        },
        {
          text: "Visible sur le site Avec",
          value: "visible",
          sortable: true,
          align: "center",
        },
      ],

      /** la recherche */
      search: null,

      /** Les établissements au chargement de la page */
      sourceConf: [],

      /** Les objets établisssment de la vue */
      superEstablishments: [],

      /**Boutons pour scroller tout en bas et tout en haut  */
      canScrollToTop: false,
      canScrollToBottom: true,
    };
  }, // END DATA
  methods: {
    /** Chargement des données de la vue */
    async load() {
      try {
        log.debug("Load ressouces on establishment avec.");
        this.loading = true;

        this.superEstablishments = [];

        // Récupération de tous les établissement (uniquement id et nom digital)
        let allSuperEstablishments = await this.serviceSuperEstablishment.getAll();
        // Récupération la conf de tous les établissements
        this.sourceConf = await this.serviceConf.getAll();

        // Parcours de toutes les établissements pour y ajouté la conf
        for (let superEstab of allSuperEstablishments) {
          let entity = {};
          // Recherche l'établissement dans la conf
          let found = this.sourceConf.find(
            (s) => s.superEstablishmentId == superEstab.id
          );

          // Etablissement trouvé dans la conf
          if (found) {
            entity = JSON.parse(JSON.stringify(found));
            entity.digitalName = superEstab.digitalName;
            entity.isNew = false;
          } else {
            // Etablissement non trouvé, on l'ajoute
            (entity.superEstablishmentId = superEstab.id),
              (entity.digitalName = superEstab.digitalName);
            entity.visible = false;
            entity.isNew = true;
          }

          this.superEstablishments.push(entity);
        }

        /** Tri par ordre alphabétique les établissements */
        this.superEstablishments.sort(function(a, b) {
          return a.digitalName.localeCompare(b.digitalName);
        });
      } catch (error) {
        console.error(error);
        this.addErrorToSnackbar(
          "chargement des données: " +
            (exceptions.toMessage(error) || "problème technique")
        );
      } finally {
        this.loading = false;
      }
    },

    async save() {
      try {
        this.loading = true;

        // Parcours tous les établissements
        for (let superEstablishment of this.superEstablishments) {
          // On forge une entitée (pas d'utilisation mémoire trop importante)
          let entity = {};
          entity.superEstablishmentId = superEstablishment.superEstablishmentId;
          entity.visible = superEstablishment.visible;

          // L'établissemnet est nouveau, on le créer en base
          if (superEstablishment.isNew) {
            await this.serviceConf.create(entity);
          }
          // Demande si l'établissements en cours a changé
          else if (this.entityHasChanged(superEstablishment)) {
            await this.serviceConf.update(entity);
          }
        }

        this.disableAlertQuit();
        this.$router.go(-1);
      } catch (error) {
        console.error(error);
        this.addErrorToSnackbar(
          "Sauvegarde des données: " +
            (exceptions.toMessage(error) || "problème technique")
        );
      }
    },

    // Initialisation des étapes du workflow en fonction des droits de l'utilisateur
    initSteps() {
      this.addStepForWorkflow("Choisir une application");
      this.addStepForWorkflow("Modifier la configuration");
      this.nextStepForWorkflow();
    },

    /** Vérifie si l'établissement en paraètre à changé par rapport à la source */
    entityHasChanged(entity) {
      let changed = false;
      let found = this.sourceConf.find(
        (s) => s.superEstablishmentId == entity.superEstablishmentId
      );
      if (found) {
        if (found.visible != entity.visible) {
          changed = true;
        }
      } else {
        if (entity.visible) changed = true;
      }

      return changed;
    },

    /** Permet à la dataTable d'obtenir une colonne */
    getItemVisible() {
      return `item.visible`;
    },

    onScroll(e) {
      if (typeof window === "undefined") return;
      const currentPos = window.pageYOffset || e.target.scrollTop || 0;
      const bottom = document.body.scrollHeight - window.innerHeight;
      this.canScrollToTop = currentPos > bottom * 0.15;
      this.canScrollToBottom = currentPos < bottom * 0.85;
    },

    toTop() {
      this.$vuetify.goTo(0);
    },

    toBottom() {
      this.$vuetify.goTo(document.body.scrollHeight);
    },
  }, //END METHODS
  computed: {
    // Parcours les établissement pour vérifier les changements
    // Ce nom de computed est nécessaire à la popup d'alert de non sauvegarde.
    hasChanged() {
      let changed = false;

      for (let e of this.superEstablishments) {
        let hasChanged = this.entityHasChanged(e);

        if (hasChanged) {
          changed = hasChanged;
        }
      }

      return changed;
    },
  }, // END computed
  mounted() {
    // Initialisation du worflow
    this.initSteps();

    this.serviceSuperEstablishment = new SuperEstablishmentService(
      this.$api.getSuperEstablishmentApi()
    );
    this.serviceConf = new ConfAppAvecSuperEstablishmentService(
      this.$api.getAppAvecSuperEstablishmentApi()
    );

    this.load();
  }, //END mounted
};
</script>

<style>
.fab-container {
  margin: 10px;
  position: fixed;
  bottom: 0;
  right: 0;
}
</style>
